<div class="container">
  <h2>Карты</h2>
  <main>
    <div *ngIf="loadingBanks">Loading banks...</div>
    <button (click)="openModal()">Добавить карту</button>

    <a href="/cards-archive"><button>Архивированные карты</button></a>
    <div style="height: 100%; width: 100%" id="main-table"></div>

    <!-- modal -->
    <div style="display: none" id="modal-create" class="modal-wrapper">
      <div class="modal-overlay" (click)="onCloseModal()" #overlay></div>
      <section class="modal" #modal>
        <div class="modal-inner">
          <!-- form -->
          <form (ngSubmit)="createCard()" [formGroup]="cardForm">
            <div class="input-container">
              <input
                required
                formControlName="number"
                placeholder="Номер карты"
              />
            </div>

            <div class="input-container">
              <input required formControlName="name" placeholder="ФИО" />
            </div>

            <div class="input-container">
              <input required formControlName="phone" placeholder="Телефон" />
            </div>
            <div
              style="margin-top: 10px; margin-bottom: 10px; text-align: center"
            >
              Автоматика по номеру счета
              <input
                type="checkbox"
                formControlName="is_using_account_number"
              />
            </div>

            <div
              class="input-container"
              *ngIf="cardForm.get('is_using_account_number').value"
            >
              <input
                required
                formControlName="account_number"
                placeholder="Номер счета"
              />
            </div>

            <div class="input-container">
              <select
                formControlName="bank"
                style="color: #000; width: 100%; margin-top: 10px"
              >
                <option
                  style="color: #000"
                  [value]="item.name"
                  *ngFor="let item of banks"
                >
                  {{ item.beautiful_name }}
                </option>
              </select>
            </div>

            <div
              style="padding: 15px; text-align: center; color: red"
              *ngIf="error != ''"
            >
              {{ error }}
            </div>

            <button>Добавить карту</button>
          </form>
          <!-- form end -->
        </div>
      </section>
    </div>
    <!-- modal end -->
  </main>
</div>
