export const date = (unix: number) => {
  return new Date(unix * 1000).toLocaleString();
};

export const jsonDecode = (item: any) => {
  return JSON.parse(item);
};

export const formatNumberWithSpaces = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getStatus = (statusCode: number | string): string => {
  if (statusCode == -1) return "Отклонен";
  if (statusCode == 0) return "Закрыта";
  if (statusCode == 1) return "В процессе";
  return "unknown";
};

export const checkBoolText = (status: any): any => {
  if (status == "true" || status == "1" || status == true || status == 1)
    return true;
  if (status == "false" || status == "0" || status == false || status == 0)
    return false;
  return false;
};

export const getCookie = (name: string) => {
  var matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const leftTime = (timestampSeconds: number) => {
  const currentUTCMilliseconds = Date.now();
  console.log("It's " + currentUTCMilliseconds + " now.")
  const currentUTCSeconds = Math.floor(currentUTCMilliseconds / 1000);
  console.log("It's " + currentUTCSeconds + " now.")

  if (timestampSeconds < currentUTCSeconds) {
    return "Ожидает закрытия";
  }

  const remainingSeconds = timestampSeconds - currentUTCSeconds;

  const days = Math.floor(remainingSeconds / (60 * 60 * 24));
  const hours = Math.floor((remainingSeconds % (60 * 60 * 24)) / (60 * 60));
  const min = Math.floor((remainingSeconds % (60 * 60)) / 60);
  const sec = remainingSeconds % 60;

  let d = days < 10 ? "0" + days : days;
  let h = hours < 10 ? "0" + hours : hours;
  let m = min < 10 ? "0" + min : min;
  let s = sec < 10 ? "0" + sec : sec;

  let res = "";

  res += days == 0 ? "" : d + "д. ";
  res += hours == 0 ? "" : h + "ч. ";

  return res + m + "м. " + s;
};
export const leftTime2 = (timestampSeconds: number) => {
  if (timestampSeconds <= 0) {
    return "Ожидает закрытия";
  }

  const remainingSeconds = timestampSeconds;

  const days = Math.floor(remainingSeconds / (60 * 60 * 24));
  const hours = Math.floor((remainingSeconds % (60 * 60 * 24)) / (60 * 60));
  const min = Math.floor((remainingSeconds % (60 * 60)) / 60);
  const sec = remainingSeconds % 60;

  let d = days < 10 ? "0" + days : days;
  let h = hours < 10 ? "0" + hours : hours;
  let m = min < 10 ? "0" + min : min;
  let s = sec < 10 ? "0" + sec : sec;

  let res = "";

  res += days == 0 ? "" : d + "д. ";
  res += hours == 0 ? "" : h + "ч. ";

  return res + m + "м. " + s;
};

export const banks = [
  { value: "sberbank", name: "Сбербанк" },
  { value: "tinkoff", name: "Тинькофф" },
  { value: "raiffeisen", name: "Райффайзен" },
  { value: "rosbank", name: "Росбанк" },
  { value: "uralsib", name: "Уралсиб" },
  { value: "alfabank", name: "Альфабанк" },
  { value: "gazprombank", name: "Газпромбанк" },
  { value: "russkiy_standart", name: "Русский стандарт" },
  { value: "ak_bars", name: "АК Барс" },
  { value: "avangard", name: "Авангард" },
  { value: "ubrir", name: "Убрир" },
  { value: "home_credit", name: "Хоум кредит" },
  { value: "ozon", name: "ОЗОН" },
  { value: "capital_bank", name: "Капитал Банк" },
  { value: "tochka_bank", name: "Точка Банк" },
  { value: "renessans_bank", name: "Ренессанс Банк" },
  { name: "Азиатско-Тихоокеанский банк (АТБ)", value: "ATB" },
  { name: "Российский национальный коммерческий банк (РНКБ)", value: "RNKB" },
  { name: "Промсвязьбанк (ПСБ)", value: "PSB" },
  { name: "Россельхозбанк (РСХБ)", value: "RSKHB" },
  { name: "Левобережный Банк", value: "levoberezhnyj" },
  { name: "ОТП Банк", value: "OTP" },
  { name: "Фора Банк", value: "fora" },
  { name: "Сибсоцбанк", value: "sibsocbank" },
  { name: "Московский кредитный банк (МКБ)", value: "MKB" },
  { name: "ЮниКредит Банк", value: "yunikredit" },
  { name: "Почта Банк", value: "pochta" },
  { name: "Транскапитал Банк", value: "transkapital" },
  { name: "Открытие Банк", value: "otkrytie" },
  { name: "Цифра Банк", value: "cifra" },
  { name: "Акцепт Банк", value: "accept" },
  { name: "Интеза Банк", value: "inteza" },
  { name: "Алтайкапитал Банк", value: "altaikapital" },
  { name: "Всероссийский банк развития регионов", value: "vserossijskij" },
  { name: "Бкс Банк", value: "bks" },
  { name: "Локо Банк", value: "loko" },
  { name: "Реалист Банк", value: "realist" },
  { name: "Финстар Банк", value: "finstar" },
  { name: "Финсервис Банк", value: "finservice" },
  { name: "Совком Банк", value: "sovkom" },
  { name: "Санкт-Петербург Банк", value: "sankt-peterburg" },
  { name: "Солидарность Банк", value: "solidarnost" },
  { name: "Яндекс Банк", value: "yandex"},
  { name: "МТС Банк", value: "mts"},
  { name: "Юнистрим Банк", value: "unistream"},
  { name: "Ингосстрах Банк", value: "ingosstrah"},
  { name: "РосДорБанк Банк", value: "rosdorbank"},
  { name: "Кредит Европа", value: "credit_europe"},
  { name: "Дом РФ", value: "dom_RF"},
  { name: "НовикомБанк", value: "novikom_bank"},
  { name: "УглеметБанк", value: "uglemet_bank"},
  { name: "МТС Деньги (ЭКСИ банк)", value: "mts_dengi_eksi_bank"},
  { name: "АкиБанк", value: "aki_bank"},
  { name: "Экспобанк", value: "ekspo_bank"},
  { name: "Синара банк", value: "sinara_bank"},
  { name: "ВТБ", value: "vtb_bank"},
  { name: "ББР Банк", value: "bbr_bank"},
  { name: "Банк Пойдём!", value: "bank_poidem"},
];

export const convertToUnixTime = (dateTimeString: string) => {
  // Извлекаем основную часть времени и временную зону
  const [datePart, timePartWithTZ] = dateTimeString.split(" ");
  const timePart = timePartWithTZ.substring(0, 15); // 09:24:02.753008
  const tzPart = timePartWithTZ.substring(15); // +03

  // Создаем строку в формате, который корректно обработает объект Date
  const isoString = `${datePart}T${timePart}${tzPart}`;

  // Создаем объект Date из строки формата ISO
  const date = new Date(isoString);

  // Возвращаем время в Unix формате
  const sec = Math.floor(date.getTime() / 1000);

  console.log(sec);
  return sec;
};

export const convertDateTimeZone = (dateTimeString: string) => {
  if (!dateTimeString) return "";
  return dateTimeString.split(".")[0];
};

export const shortString = (s: string) => {
  if (s.length < 8) return s;
  return `${s[0]}${s[1]}${s[2]}...${s[s.length - 5]}${s[s.length - 4]}${
    s[s.length - 3]
  }${s[s.length - 2]}${s[s.length - 1]}`;
};
