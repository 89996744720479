<div style="height: 100vh; width: 100vh">
  <div class="block_send_dist">
    <h2>
      <span>Карта #{{ cardId }} </span>
    </h2>

    <div *ngIf="loadingCard">Loading card...</div>

    <div class="add-deposit" style="margin-top: 10px" *ngIf="!loadingCard">
      <div class="item">
        <label>ФИО</label>
        <input type="text" [(ngModel)]="card.name" />
      </div>
      <div class="item">
        <label>Номер карты</label>
        <input type="text" [(ngModel)]="card.number" />
      </div>
      <div class="item">
        <label>Номер телефона</label>
        <input type="text" [(ngModel)]="card.phone_number" />
      </div>
      <div class="item">
        <label>Банк</label>
        <select
          [(ngModel)]="card.bank"
          style="color: #000; width: 100%; margin-top: 10px"
        >
          <option
            style="color: #000"
            [value]="item.name"
            *ngFor="let item of banks"
          >
            {{ item.beautiful_name }}
          </option>
        </select>
      </div>

      <div>СБП</div>
      <input type="checkbox" [(ngModel)]="card.is_sbp" />

      <div style="margin-top: 10px; margin-bottom: 10px; text-align: center">
        Автоматика по номеру счета
        <input type="checkbox" [(ngModel)]="card.is_using_account_number" />
      </div>

      <div *ngIf="card.active" style="margin-top: 10px; margin-bottom: 10px; text-align: center; color: red">
       Карта активна. <br> Редактировать можно только не активные карты
      </div>

      <div class="input-container" *ngIf="card.is_using_account_number">
        <input [(ngModel)]="card.account_number" placeholder="Номер счета" />
      </div>

      <button style="margin-top: 10px" *ngIf="loadingEditCard">Loading</button>
      <button
        style="margin-top: 10px"
        (click)="editCard()"
        *ngIf="!loadingEditCard"
      >
        Сохранить информацию
      </button>
    </div>
  </div>
</div>
